//--------------------------------------------------------------
// Imports Section
//--------------------------------------------------------------
import React from 'react';
import { blue } from '@material-ui/core/colors';
import SvgIcon from '@material-ui/core/SvgIcon';


//--------------------------------------------------------------
// Component Section
//--------------------------------------------------------------
const SkypeIcon = (props)  => {
    return (
        <SvgIcon x="0px" y="0px" viewBox="0 0 291.364 291.364" {...props}>
            <path style={{fill:'#4b3621'}} d="M282.966,176.612c2.094-9.495,3.204-19.309,3.204-29.405c0-75.661-62.197-137.011-138.941-137.011
                c-8.093,0-16.032,0.692-23.752,2.003C111.061,4.479,96.367,0,80.618,0C36.101,0,0.032,35.577,0.032,79.475
                c0,14.657,4.051,28.385,11.07,40.193c-1.848,8.894-2.813,18.107-2.813,27.539c0,75.688,62.197,134.753,138.932,134.753
                c8.694,0,17.197-0.792,25.436-2.294c11.352,6.008,24.316,11.698,38.099,11.698c44.508,0,80.577-35.568,80.577-79.466
                C291.333,199.235,288.319,187.245,282.966,176.612z"
            />
            <path style={{fill:'#85754e'}} d="M210.6,203.131c-5.362,7.62-13.21,13.61-23.542,17.916c-10.333,4.342-22.568,6.491-36.724,6.491
                c-16.978,0-30.98-2.95-42.023-8.858c-7.811-4.242-14.174-9.923-19.072-17.006c-4.88-7.083-7.328-13.983-7.328-20.693
                c0-3.878,1.466-7.21,4.397-9.969c2.931-2.795,6.673-4.169,11.198-4.169c3.678,0,6.8,1.065,9.331,3.241
                c2.549,2.158,4.725,5.353,6.509,9.604c2.194,5.053,4.552,9.268,7.11,12.645c2.531,3.377,6.118,6.181,10.724,8.375
                c4.625,2.176,10.697,3.286,18.198,3.286c10.324,0,18.717-2.203,25.181-6.637c6.445-4.415,9.686-9.959,9.686-16.578
                c0-5.244-1.748-9.532-5.226-12.782c-3.487-3.277-7.984-5.781-13.501-7.501c-5.508-1.739-12.882-3.578-22.131-5.517
                c-12.354-2.649-22.705-5.781-31.025-9.331c-8.33-3.578-14.957-8.43-19.837-14.575c-4.898-6.172-7.338-13.819-7.338-22.95
                c0-8.721,2.576-16.45,7.747-23.214c5.162-6.773,12.618-11.971,22.413-15.613c9.777-3.641,21.285-5.453,34.494-5.453
                c10.56,0,19.7,1.211,27.411,3.605c7.711,2.422,14.093,5.626,19.172,9.623c5.089,3.997,8.812,8.184,11.152,12.581
                c2.349,4.37,3.523,8.685,3.523,12.836c0,3.814-1.457,7.274-4.388,10.324c-2.931,3.059-6.591,4.597-10.97,4.597
                c-3.997,0-7.028-0.929-9.104-2.758c-2.076-1.839-4.324-4.843-6.737-9.022c-3.141-5.972-6.882-10.633-11.27-13.974
                c-4.388-3.35-11.416-5.025-21.121-5.025c-8.985,0-16.25,1.803-21.767,5.453c-5.517,3.623-8.284,8.011-8.284,13.118
                c0,3.15,0.956,5.899,2.822,8.202c1.866,2.321,4.452,4.279,7.756,5.927c3.268,1.657,6.6,2.95,9.959,3.905
                c3.359,0.938,8.922,2.294,16.678,4.097c9.686,2.085,18.471,4.397,26.337,6.91c7.857,2.522,14.557,5.59,20.074,9.167
                c5.508,3.605,9.823,8.175,12.909,13.692c3.086,5.499,4.643,12.244,4.643,20.238C218.639,186.899,215.945,195.502,210.6,203.131z"
            />
        </SvgIcon>
    )
}


//--------------------------------------------------------------
// Imports Section
//--------------------------------------------------------------
export default SkypeIcon
